import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useFirebase } from "react-redux-firebase";
import { withRouter } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import { Help } from "@mui/icons-material";
import Modal from "../layout/Modal";

const LoginFirebase = ({ history }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const firebase = useFirebase();
  const [open, setOpen] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      await firebase.login({
        email,
        password,
      });

      const user = await firebase.auth().currentUser;
      const emailVerified = user.emailVerified;

      if (emailVerified) {
        window.location.href = "https://findabuddy.xyz/dashboard";
      } else {
        await firebase.auth().currentUser.sendEmailVerification();
        window.location.href = "https://findabuddy.xyz/verify";
      }
    } catch (error) {
      const errorM = error.message;
      const message = errorM.split(":")[1];
      if (message) {
        const errorMessage = message.split(".")[0];
        const notify = () => toast.error(errorMessage);
        notify();
      } else {
        const notify = () => toast.error(errorM);
        notify();
      }
    }
  };

  const handleOpen = () => setOpen(true);

  const handleClose = () => setOpen(false);

  useEffect(() => {
    const reloadCount = sessionStorage.getItem("reloadCount");
    if (reloadCount < 2) {
      sessionStorage.setItem("reloadCount", String(reloadCount + 1));
      window.location.reload();
    } else {
      sessionStorage.removeItem("reloadCount");
    }
  }, []);

  return (
    <div className="container">
      <div style={{ marginTop: "4rem" }} className="row">
        <div className="col s8 offset-s2">
          <ToastContainer hideProgressBar={true} theme="colored" />
          <Link to="/" className="btn-flat waves-effect">
            <i className="material-icons left">keyboard_backspace</i> Back
          </Link>
          <div
            className="col s12"
            style={{
              paddingLeft: "11.250px",
            }}
          >
            <h4>
              <b>Login</b> below
            </h4>
            <p className="grey-text text-darken-1">
              Don't have an account? <Link to="/register">Register</Link>
            </p>
          </div>
          <form onSubmit={handleSubmit}>
            <div className="input-field col s12">
              <label htmlFor="email">Email</label>
              <input
                id="email"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className="input-field col s12">
              <label htmlFor="password">Password</label>
              <input
                id="password"
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <div
                style={{
                  display: "flex",
                }}
              >
                {/* <Help /> */}
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    handleOpen();
                  }}
                  style={{
                    border: "none",
                    background: "white",
                    cursor: "pointer",
                  }}
                >
                  Forgot Password?
                </button>
              </div>
            </div>
            <Modal open={open} handleClose={handleClose} />
            <div
              className="col s12"
              style={{
                paddingLeft: "11.250px",
              }}
            >
              <button
                style={{
                  width: "150px",
                  borderRadius: "3px",
                  letterSpacing: "1.5px",
                  marginTop: "1rem",
                }}
                type="submit"
                className="btn btn-large waves-effect waves-light hoverable black accent-3"
              >
                Login
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default withRouter(LoginFirebase);
