import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Navbar from "../layout/Navbar";
import Card from "../layout/Card";
import Footer from "../layout/Footer";
import { Link } from "react-router-dom";
import axios from "axios";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { withRouter } from "react-router-dom";
import Loading from "../layout/Loading";
import { useSelector } from "react-redux";
import { Box } from "@mui/material";

const Dashboard = (props) => {
  const firebaseReducer = useSelector((state) => state.firebase);
  const { email } = firebaseReducer.auth;
  const [loading, setLoading] = useState(true);

  const [isAvailable, setIsAvailable] = useState(false);
  const [domain, setDomain] = useState("");
  const [allUsers, setAllUsers] = useState([]);
  const [details, setDetails] = useState({
    bio: "",
    linkedin: "",
    domain: "",
  });
  const [displayProp, setDisplayProp] = useState("none");

  useEffect(() => {
    const loadData = async () => {
      await new Promise((r) => setTimeout(r, 3000));
      setLoading((loading) => !loading);
    };
    loadData();
  }, []);

  useEffect(() => {
    fetchUserData();
  }, []);

  useEffect(() => {
    fetchUsersWithDomain();
  }, [domain]);

  async function fetchUserData() {
    const userData = await fetch(
      "https://find-a-buddy.onrender.com/api/users/" + email
    );
    const json = await userData.json();
    const mydomain = json.domain;

    if (mydomain === undefined) {
      setIsAvailable(false);
    } else {
      setIsAvailable(true);
      setDomain(mydomain);
    }
  }

  async function fetchUsersWithDomain() {
    const users = await fetch(
      `https://find-a-buddy.onrender.com/api/users/domain/${domain}/${email}`
    );

    if (users.status === 200) {
      const json = await users.json();
      setAllUsers(json);
    } else {
      const json = [];
      setAllUsers(json);
    }
  }

  const onChange = (e) => {
    const { name, value } = e.target;
    setDetails({
      ...details,
      [name]: value,
    });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const updatedUser = {
      bio: details.bio,
      linkedin: details.linkedin,
      domain: details.domain,
    };

    axios
      .put(
        `https://find-a-buddy.onrender.com/api/users/details/${email}`,
        updatedUser
      )
      .then((res) => {
        props.history.push("/dashboard");
        window.location.reload();
      })
      .catch((err) => {
        if (err.response.data.invalidurl === "Invalid LinkedIn profile URL") {
          setDisplayProp("block");
        }
      });
  };

  const styles = {
    displayPage: {
      height: allUsers.length > 0 && allUsers.length < 3 ? "82vh" : "100%",
      textAlign: "center",
      paddingTop: "50px",
      background: "#eceff1",
    },
  };

  if (loading) {
    return (
      <div
        style={{
          background: "#eceff1",
          height: "100vh",
          paddingLeft: "50%",
          paddingTop: "10%",
        }}
      >
        <Loading />
      </div>
    );
  } else {
    return (
      <div style={{ background: "#eceff1" }}>
        <div>
          <Navbar />
          {isAvailable === true && allUsers.length > 0 ? (
            <div
              style={{
                background: "#a4eae4",
                paddingTop: "0.1em",
                textAlign: "center",
              }}
            >
              <p
                style={{
                  fontSize: "1.2em",
                  margin: "auto",
                }}
              >
                {allUsers.length} people are looking for {domain} buddies! Start
                the conversation by sending them a message on LinkedIn.
              </p>
            </div>
          ) : (
            ""
          )}
        </div>
        {isAvailable === true ? (
          allUsers.length === 0 ? (
            <div style={{ height: "77vh" }}>
              <div
                style={{
                  marginLeft: "50px",
                  width: "100%",
                }}
              >
                <h1>No Users Found</h1>
                <h5>
                  Currently, there are no other users for the selected domain.
                  <br />
                  Either change the domain or check back after sometime.
                </h5>
              </div>
            </div>
          ) : (
            <div style={styles.displayPage}>
              {allUsers.map((k) => (
                <div
                  style={{
                    background: "#f6f8fa",
                    width: "40%",
                    borderRadius: "5px",
                    margin: "auto",
                    marginBottom: "30px",
                  }}
                  key={k.id}
                  className="card-container-outer"
                >
                  <Card
                    name={k.name}
                    domain={k.domain}
                    linkedin={k.linkedin}
                    bio={k.bio}
                  />
                </div>
              ))}
            </div>
          )
        ) : (
          <Box
            sx={{
              height: "82vh",
              width: { sm: "40%", xs: "90%" },
              padding: "3em",
            }}
          >
            <div className="col s8 offset-s2">
              <Link to="/" className="btn-flat waves-effect">
                <i className="material-icons left">keyboard_backspace</i> Back
                to home
              </Link>
              <div className="col s12" style={{ paddingLeft: "11.250px" }}>
                <h4>
                  <b>Add Details</b> below to proceed further
                </h4>
                <p>* All fields are required</p>
              </div>
            </div>
            <form noValidate onSubmit={onSubmit}>
              <div className="input-field col s12">
                <input
                  onChange={onChange}
                  value={details.bio}
                  name="bio"
                  type="text"
                />
                <label htmlFor="bio">Bio</label>
              </div>
              <div className="input-field col s12">
                <input
                  onChange={onChange}
                  value={details.linkedin}
                  name="linkedin"
                  type="text"
                />
                <label htmlFor="linkedin">Linkedin</label>
                <span className="red-text" style={{ display: displayProp }}>
                  Invalid LinkedIn profile URL
                </span>
              </div>
              <div className="input-field col s12">
                <p htmlFor="domain">What are you looking for?</p>
                <Select
                  labelId="demo-simple-select-standard-label"
                  id="demo-simple-select-standard"
                  value={details.domain}
                  onChange={onChange}
                  name="domain"
                  sx={{ width: "100%" }}
                >
                  <MenuItem value="leetcode">Leetcode</MenuItem>
                  <MenuItem value="hackathon">Hackathon</MenuItem>
                  <MenuItem value="project">Project</MenuItem>
                  <MenuItem value="competitive-programming">
                    Competitive Programming
                  </MenuItem>
                </Select>
              </div>
              <div className="col s12" style={{ paddingLeft: "11.250px" }}>
                <button
                  style={{
                    width: "150px",
                    borderRadius: "3px",
                    letterSpacing: "1.5px",
                    marginTop: "1rem",
                  }}
                  type="submit"
                  className="btn btn-large waves-effect waves-light hoverable black accent-3"
                  disabled={
                    !details.bio.replace(/\s/g, "").length ||
                    !details.domain.replace(/\s/g, "").length ||
                    !details.linkedin.replace(/\s/g, "").length
                  }
                >
                  Submit
                </button>
              </div>
            </form>
          </Box>
        )}
        <Footer />
      </div>
    );
  }
};

Dashboard.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(withRouter(Dashboard));
