import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Navbar from "../layout/Navbar";
import Footer from "../layout/Footer";
import axios from "axios";
import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { useSelector } from "react-redux";
import { Box } from "@mui/material";

const EditProfile = (props) => {
  const firebaseReducer = useSelector((state) => state.firebase);
  const { email } = firebaseReducer.auth;

  const [data, setData] = useState({
    bio: "",
    linkedin: "",
    domain: "",
  });

  const [displayProp, setDisplayProp] = useState("none");

  useEffect(() => {
    fetchUserData();
  }, []);

  async function fetchUserData() {
    const userData = await fetch(
      "https://find-a-buddy.onrender.com/api/users/" + email
    );
    const json = await userData.json();
    setData(json);
  }

  const onChange = (e) => {
    const { name, value } = e.target;

    setData({
      ...data,
      [name]: value,
    });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const updatedUser = {
      bio: data.bio,
      linkedin: data.linkedin,
      domain: data.domain,
    };

    axios
      .put(
        `https://find-a-buddy.onrender.com/api/users/details/${email}`,
        updatedUser
      )
      .then((res) => {
        props.history.push("/profile");
      })
      .catch((err) => {
        if (err.response.data.invalidurl === "Invalid LinkedIn profile URL") {
          setDisplayProp("block");
        }
      });
  };

  return (
    <div
      style={{
        background: "#eceff1",
      }}
    >
      <Navbar />
      <Box
        sx={{
          height: { sm: "82vh", xs: "90vh" },
          width: { sm: "40%", xs: "80%" },
          padding: "3em",
        }}
      >
        <div className="col s8 offset-s2">
          <Link to="/profile" className="btn-flat waves-effect">
            <i className="material-icons left">keyboard_backspace</i>
            Back to Profile
          </Link>
          <div
            className="col s12"
            style={{
              paddingLeft: "11.250px",
            }}
          >
            <h4>
              <b>Update Details</b> below
            </h4>
            <p>* All fields are required</p>
          </div>
        </div>
        <form noValidate onSubmit={onSubmit}>
          <div className="input-field col s12">
            <p htmlFor="bio">Bio</p>
            <input
              onChange={onChange}
              value={data.bio}
              name="bio"
              type="text"
            />
          </div>
          <div className="input-field col s12">
            <p htmlFor="linkedin">Linkedin</p>
            <input
              onChange={onChange}
              value={data.linkedin}
              name="linkedin"
              type="text"
            />
            <span className="red-text" style={{ display: displayProp }}>
              Invalid LinkedIn profile URL
            </span>
          </div>
          <div className="input-field col s12">
            <p htmlFor="domain">What are you looking for?</p>
            <Select
              labelId="demo-simple-select-standard-label"
              id="demo-simple-select-standard"
              value={data.domain}
              onChange={onChange}
              name="domain"
              sx={{ width: "100%" }}
            >
              <MenuItem value="leetcode">Leetcode</MenuItem>
              <MenuItem value="hackathon">Hackathon</MenuItem>
              <MenuItem value="project">Project</MenuItem>
              <MenuItem value="competitive-programming">
                Competitive Programming
              </MenuItem>
            </Select>
          </div>
          <div
            className="col s12"
            style={{
              paddingLeft: "11.250px",
            }}
          >
            <button
              style={{
                width: "150px",
                borderRadius: "3px",
                letterSpacing: "1.5px",
                marginTop: "1rem",
              }}
              type="submit"
              className="btn btn-large waves-effect waves-light hoverable black accent-3"
              disabled={
                !data.bio.replace(/\s/g, "").length ||
                !data.domain.replace(/\s/g, "").length ||
                !data.linkedin.replace(/\s/g, "").length
              }
            >
              Submit
            </button>
          </div>
        </form>
      </Box>
      <Footer />
    </div>
  );
};

EditProfile.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(withRouter(EditProfile));
