import React from "react";

const Footer = () => {
  return (
    <div
      style={{
        background: "#eceff1",
        bottom: 0,
        textAlign: "center",
        padding: "1em",
      }}
    >
      <p>
        For any feedback, please mail at{" "}
        <a href="mailto:findabuddy.xyz@gmail.com">findabuddy.xyz@gmail.com</a>
      </p>
    </div>
  );
};

export default Footer;
