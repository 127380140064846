import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import rootReducer from "./reducers";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
// import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyDyxRotwUUHz7SrH0bNPSrnP575IABuXTc",
  authDomain: "find-a-buddy-6a195.firebaseapp.com",
  projectId: "find-a-buddy-6a195",
  storageBucket: "find-a-buddy-6a195.appspot.com",
  messagingSenderId: "458500475841",
  appId: "1:458500475841:web:8fcec491baa4ba12a17d40",
  measurementId: "G-GM9115F9E3",
};

firebase.initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);

// react-redux-firebase config
const rrfConfig = {
  userProfile: "users",
  // useFirestoreForProfile: true // Firestore for Profile instead of Realtime DB
};

const initialState = {};

const middleware = [thunk];

const store = createStore(
  rootReducer,
  initialState,
  compose(
    applyMiddleware(...middleware),
    (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
      window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__()) ||
      compose
  )
);

export const rrfProps = {
  firebase,
  config: rrfConfig,
  dispatch: store.dispatch,
};

export default store;
