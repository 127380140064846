import React from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
// import waves from "./wave_background.svg";
// import constellation from "./endless-constellation.svg";
import { Twitter, Instagram, Mail } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";

const Landing = () => {
  const firebaseReducer = useSelector((state) => state.firebase);
  const { isEmpty, emailVerified } = firebaseReducer.auth;
  return (
    <div style={{ background: "#F6F6F2" }}>
      <div
        style={
          {
            // backgroundImage: `url(${waves})`,
            // backgroundImage: `url(${constellation})`,
            // backgroundSize: "cover",
          }
        }
      >
        <div
          className="nav-wrapper"
          style={{
            background: "transparent",
            display: "flex",
            justifyContent: "space-between",
            paddingTop: "1.5em",
          }}
        >
          <div>
            <Link
              to="/"
              style={{
                fontSize: "2em",
                marginLeft: "1em",
                fontWeight: "bold",
                color: "black",
              }}
            >
              find a <b>buddy</b>.
            </Link>
          </div>
          <div
            style={{
              display: "flex",
            }}
          >
            <div
              style={{
                cursor: "pointer",
                marginRight: "1.2em",
              }}
            >
              <a
                href={
                  !isEmpty && emailVerified
                    ? "https://findabuddy.xyz/dashboard"
                    : "https://findabuddy.xyz/login"
                }
                style={{
                  color: "white",
                  fontSize: "1.2em",
                  background: "#6FB3B8",
                  padding: "0.6em",
                  borderRadius: "7px",
                }}
              >
                Log In
              </a>
            </div>
          </div>
        </div>
        <div style={{ height: "80vh" }} className="container valign-wrapper">
          <div className="row">
            <div className="col s12 center-align">
              <Typography
                sx={{
                  fontSize: { sm: "5em", xs: "2.7em" },
                  marginTop: "-1em",
                }}
              >
                <b>
                  <span style={{ color: "#388087" }}>Collaborations</span> made
                  easier
                </b>
              </Typography>
              <Typography
                className="flow-text grey-text text-darken-1"
                sx={{
                  marginTop: "-0.5em",
                  fontSize: { sm: "2em", xs: "1.3em" },
                }}
              >
                Connect with others who are working towards the same goals
              </Typography>
              <br />
              <div className="col s12">
                <Link
                  to={!isEmpty && emailVerified ? "/dashboard" : "/register"}
                  style={{
                    borderRadius: "3px",
                    letterSpacing: "1.5px",
                  }}
                  className="btn btn-large waves-effect waves-light hoverable black accent-3"
                >
                  Get Started
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div style={{ padding: "2em" }}>
          <Box sx={{ display: { sm: "flex", xs: "block" } }}>
            <Box
              sx={{
                width: { sm: "50%", xs: "100%" },
                height: "100%",
                marginLeft: { sm: "10%", xs: "0%" },
                textAlign: { sm: "left", xs: "center" },
              }}
            >
              <img
                src={require("./people_search.svg")}
                alt="people_search"
                width="45%"
              />
            </Box>
            <Box
              sx={{
                width: { sm: "50%", xs: "100%" },
                height: "100%",
              }}
            >
              <Box
                sx={{
                  marginRight: { sm: "20%", xs: "0%" },
                  textAlign: { sm: "left", xs: "center" },
                }}
              >
                <p style={{ fontSize: "2em" }}>
                  {" "}
                  <b>
                    Discover like-minded collaborators from around the world
                    with just a few clicks
                  </b>
                </p>
                <p
                  className="flow-text grey-text text-darken-1"
                  style={{
                    marginTop: "-1em",
                  }}
                >
                  You can easily connect with potential collaborators,
                  regardless of distance or time zone.
                </p>
              </Box>
            </Box>
          </Box>
        </div>
        <Box
          sx={{
            padding: "2em",
            marginTop: { sm: "2em", xs: "1em" },
          }}
        >
          <Box sx={{ display: { sm: "flex", xs: "block" } }}>
            <Box
              sx={{
                width: { sm: "50%", xs: "100%" },
                height: "100%",
                display: { sm: "none", xs: "block" },
                textAlign: "center",
              }}
            >
              <img
                src={require("./search.svg")}
                alt="search"
                width="40%"
                className="img-search-mobile"
              />
            </Box>
            <Box
              sx={{
                width: { sm: "50%", xs: "100%" },
                height: "100%",
              }}
            >
              <Box
                sx={{
                  marginLeft: { sm: "20%", xs: "0%" },
                  textAlign: { sm: "left", xs: "center" },
                }}
              >
                <p style={{ fontSize: "2em" }}>
                  {" "}
                  <b>Easily browse through profiles</b>
                </p>
                <p
                  className="flow-text grey-text text-darken-1"
                  style={{
                    marginTop: "-1em",
                  }}
                >
                  Stop searching high and low for a collaborator! Our platform
                  lets you select a domain and easily browse through profiles of
                  people who are eager to work with you
                </p>
              </Box>
            </Box>
            <Box
              sx={{
                width: { sm: "50%", xs: "100%" },
                height: "100%",
                display: { sm: "block", xs: "none" },
              }}
            >
              <img
                src={require("./search.svg")}
                alt="search"
                style={{
                  marginLeft: "30%",
                  width: "35%",
                }}
                className="img-search"
              />
            </Box>
          </Box>
        </Box>
        <div
          style={{
            padding: "10em 4em 2em 4em",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-evenly",
              textAlign: "center",
              fontSize: "3em",
            }}
          >
            <a
              href="https://twitter.com/findabuddy_xyz"
              style={{ color: "black" }}
            >
              <Twitter fontSize="2em" />
            </a>
            <a
              href="https://www.instagram.com/findabuddy_xyz"
              style={{ color: "black" }}
            >
              <Instagram fontSize="2em" />
            </a>
            <a
              href="mailto:findabuddy.xyz@gmail.com"
              style={{ color: "black" }}
            >
              <Mail fontSize="2em" />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Landing;
