import React, { useState, useEffect } from "react";
import { Link, withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { connect, useSelector } from "react-redux";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Avatar from "@mui/material/Avatar";
import { useFirebase } from "react-redux-firebase";
import { Box } from "@mui/material";

const Navbar = (props) => {
  const firebase = useFirebase();
  const firebaseReducer = useSelector((state) => state.firebase);
  const { email } = firebaseReducer.auth;
  // const { auth } = firebaseReducer;
  const [domain, setDomain] = useState({});
  const [name, setName] = useState("");

  useEffect(() => {
    fetchUserData();
  }, []);

  async function fetchUserData() {
    const userData = await fetch(
      "https://find-a-buddy.onrender.com/api/users/" + email
    );
    const json = await userData.json();
    console.log(json);
    setDomain(json.domain);
    setName(json.name);
  }

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const display = name.substring(0, 8) + "...";

  return (
    <div className="navbar-fixed">
      <nav className="z-depth-0">
        <div
          className="nav-wrapper"
          style={{
            backgroundColor: "black",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ fontSize: { sm: "2em", xs: "1.3em" } }}>
            <Link
              to="/"
              style={{
                marginLeft: "1em",
                fontWeight: "bold",
              }}
            >
              find a buddy.
            </Link>
          </Box>
          <div
            style={{
              display: "flex",
            }}
          >
            <div
              style={{
                marginRight: "1em",
                fontSize: "1.2em",
                color: "#a4eae4",
                backgroundColor: "black",
              }}
            >
              {/* {auth.displayName} */}
              {name.length > 8 ? display : name}
            </div>
            <div
              style={{
                cursor: "pointer",
                marginRight: "1em",
                marginTop: "1em",
              }}
            >
              <Avatar onClick={handleClick} />
            </div>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              <MenuItem
                sx={{
                  textAlign: "center",
                }}
                disabled={
                  props.location.pathname === "/profile" || domain === undefined
                }
              >
                <Link
                  to="/profile"
                  style={{
                    color: "black",
                    fontSize: "1.1em",
                    paddingLeft: "2em",
                    paddingRight: "2em",
                  }}
                >
                  Profile
                </Link>
              </MenuItem>
              <MenuItem
                sx={{
                  textAlign: "center",
                }}
                disabled={props.location.pathname === "/dashboard"}
              >
                <Link
                  to="/dashboard"
                  style={{
                    color: "black",
                    fontSize: "1.1em",
                    paddingLeft: "2em",
                    paddingRight: "2em",
                  }}
                >
                  Dashboard
                </Link>
              </MenuItem>
              <button
                style={{
                  color: "black",
                  fontSize: "1.1em",
                  paddingLeft: "2em",
                  paddingRight: "2em",
                  border: "none",
                  background: "white",
                  width: "10em",
                }}
                onClick={() => firebase.logout()}
              >
                <MenuItem style={{ width: "100%" }}>Logout</MenuItem>
              </button>
            </Menu>
          </div>
        </div>
      </nav>
    </div>
  );
};

Navbar.propTypes = {
  logoutUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(withRouter(Navbar));
