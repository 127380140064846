import React from "react";

const Tag = ({ name }) => {
  return (
    <div>
      <p
        style={{
          background: "#a4eae4",
          paddingLeft: "0.5em",
          paddingRight: "0.5em",
          borderRadius: "2em",
        }}
      >
        {name}
      </p>
    </div>
  );
};

export default Tag;
