import * as React from "react";
import Stack from "@mui/material/Stack";
import CircularProgress from "@mui/material/CircularProgress";

export default function CircularColor() {
  return (
    <Stack sx={{ color: "black" }}>
      <CircularProgress color="inherit" />
    </Stack>
  );
}
