import React, { useEffect, useState } from "react";
import Navbar from "../layout/Navbar";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Footer from "../layout/Footer";
import Loading from "../layout/Loading";
import { useSelector } from "react-redux";
import { Box, Typography } from "@mui/material";

const Profile = () => {
  const firebaseReducer = useSelector((state) => state.firebase);
  const { email } = firebaseReducer.auth;
  const [loading, setLoading] = useState(true);

  const [data, setData] = useState({
    name: "",
    bio: "",
    linkedin: "",
    domain: "",
  });

  useEffect(() => {
    const loadData = async () => {
      await new Promise((r) => setTimeout(r, 3000));
      setLoading((loading) => !loading);
    };
    loadData();
  }, []);

  useEffect(() => {
    fetchUserData();
  }, []);

  async function fetchUserData() {
    const userData = await fetch(
      "https://find-a-buddy.onrender.com/api/users/" + email
    );
    const json = await userData.json();
    setData(json);
  }

  if (loading) {
    return (
      <div
        style={{
          background: "#eceff1",
          height: "100vh",
          paddingLeft: "50%",
          paddingTop: "10%",
        }}
      >
        <Loading />
      </div>
    );
  } else {
    return (
      <div style={{ background: "#eceff1" }}>
        <Navbar />
        <Box
          sx={{
            marginLeft: "3em",
            marginTop: "3em",
            height: "76vh",
          }}
        >
          <div style={{ display: "flex" }}>
            <Typography
              sx={{ width: "6em", fontSize: { sm: "1.7em", xs: "1.2em" } }}
            >
              <b>Name</b>
            </Typography>
            <Typography
              sx={{ marginLeft: "1em", fontSize: { sm: "1.7em", xs: "1.2em" } }}
            >
              {data.name}
            </Typography>
          </div>
          <div style={{ display: "flex" }}>
            <Typography
              sx={{ width: "6em", fontSize: { sm: "1.7em", xs: "1.2em" } }}
            >
              <b>Bio</b>
            </Typography>
            <Typography
              sx={{
                marginLeft: "1em",
                wordBreak: "break-all",
                fontSize: { sm: "1.7em", xs: "1.2em" },
              }}
            >
              {data.bio}
            </Typography>
          </div>
          <div style={{ display: "flex" }}>
            <Typography
              sx={{ width: "6em", fontSize: { sm: "1.7em", xs: "1.2em" } }}
            >
              <b>LinkedIn</b>
            </Typography>
            <Typography
              sx={{
                marginLeft: "1em",
                wordBreak: "break-all",
                fontSize: { sm: "1.7em", xs: "1.2em" },
                width: { sm: "88%", xs: "67%" },
              }}
            >
              {data.linkedin}
            </Typography>
          </div>
          <div style={{ display: "flex" }}>
            <Typography
              sx={{ width: "6em", fontSize: { sm: "1.7em", xs: "1.2em" } }}
            >
              <b>Looking For</b>
            </Typography>
            <Typography
              sx={{
                marginLeft: "1em",
                wordBreak: "break-all",
                fontSize: { sm: "1.7em", xs: "1.2em" },
              }}
            >
              {data.domain}
            </Typography>
          </div>
          <Link
            style={{
              width: "fit",
              borderRadius: "3px",
              letterSpacing: "1.5px",
              marginTop: "1rem",
              marginRight: "1em",
            }}
            className="btn btn-large waves-effect waves-light hoverable black accent-3"
            to="/edit"
          >
            Edit
          </Link>
        </Box>
        <Footer />
      </div>
    );
  }
};

Profile.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(Profile);
