import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useFirebase } from "react-redux-firebase";
import { withRouter } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const SignupFirebase = ({ history }) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const firebase = useFirebase();

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const { user } = await firebase.createUser({
        email,
        password,
      });

      if (name === "") {
        throw new Error("Name is required");
      }

      await user.updateProfile({
        displayName: name,
      });

      const userData = {
        name,
        email,
        password,
      };

      axios
        .post("https://find-a-buddy.onrender.com/api/users/register", userData)
        .then((res) => {})
        .catch((err) => console.log("err", err));

      await user.sendEmailVerification();

      history.push("/verify");
    } catch (error) {
      const errorM = error.message;
      const message = errorM.split(":")[1];
      if (message) {
        const errorMessage = message.split(".")[0];
        const notify = () => toast.error(errorMessage);
        notify();
      } else {
        const notify = () => toast.error(errorM);
        notify();
      }
    }
  };

  return (
    <div className="container">
      <ToastContainer hideProgressBar={true} theme="colored" />
      <div style={{ marginTop: "4rem" }} className="row">
        <div className="col s8 offset-s2">
          <Link to="/" className="btn-flat waves-effect">
            <i className="material-icons left">keyboard_backspace</i> Back
          </Link>
          <div
            className="col s12"
            style={{
              paddingLeft: "11.250px",
            }}
          >
            <h4>
              <b>Register</b> below
            </h4>
            <p className="grey-text text-darken-1">
              Already have an account? <Link to="/login">Login</Link>
            </p>
          </div>
          <form onSubmit={handleSubmit}>
            <div className="input-field col s12">
              <input
                onChange={(e) => setName(e.target.value)}
                value={name}
                id="name"
                type="text"
              />
              <label htmlFor="name">Name</label>
            </div>
            <div className="input-field col s12">
              <label htmlFor="email">Email</label>
              <input
                id="email"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className="input-field col s12">
              <label htmlFor="password">Password</label>
              <input
                id="password"
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
            <div
              className="col s12"
              style={{
                paddingLeft: "11.250px",
              }}
            >
              <button
                style={{
                  width: "150px",
                  borderRadius: "3px",
                  letterSpacing: "1.5px",
                  marginTop: "1rem",
                }}
                type="submit"
                className="btn btn-large waves-effect waves-light hoverable black accent-3"
              >
                Register
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default withRouter(SignupFirebase);
