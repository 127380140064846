import React from "react";
import Alert from "@mui/material/Alert";

const VerifyNote = () => {
  return (
    <div>
      <Alert
        severity="success"
        style={{
          marginTop: "15%",
          width: "60%",
          marginLeft: "20%",
          padding: "2em",
        }}
      >
        <div style={{ fontWeight: "bold", fontSize: "2em" }}>
          A verification link has been sent to your email account
        </div>
        <div style={{ fontSize: "1.3em" }}>
          Please click on the link that has been sent to your email account to
          verify your email and continue the Login process. If you don't get the
          link in Inbox, please check Spam.
        </div>
        <a
          href="https://findabuddy.xyz/login"
          style={{
            borderRadius: "3px",
            border: "2px solid black",
            marginTop: "10px",
          }}
          className="btn btn-flat waves-effect black white-text"
        >
          Go to Log In
        </a>
      </Alert>
    </div>
  );
};

export default VerifyNote;
