import * as React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { LinkedIn } from "@mui/icons-material";
import Tag from "./Tag";

const OutlinedCard = ({ name, domain, linkedin, bio }) => {
  return (
    <Box sx={{ width: "100%" }}>
      <Card variant="outlined">
        <div>
          <div
            style={{
              display: "flex",
              justifyContent: "right",
              float: "right",
              width: "100%",
              paddingRight: "1em",
            }}
          >
            <Tag name={domain} />
          </div>
          <CardContent>
            <Typography variant="h5" component="div">
              {name}
            </Typography>
            <Typography sx={{ mb: 0 }} color="text.secondary">
              {bio}
            </Typography>
          </CardContent>
          <CardActions sx={{ justifyContent: "center" }}>
            <a href={linkedin}>
              <LinkedIn />
            </a>
          </CardActions>
        </div>
      </Card>
    </Box>
  );
};

export default OutlinedCard;
