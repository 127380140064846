import React, { useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { useFirebase } from "react-redux-firebase";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

const BasicModal = ({ handleClose, open }) => {
  const [email, setEmail] = useState("");
  const firebase = useFirebase();

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          Forgot Password?
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          Please click on the link that has been sent to your email account to
          reset password and continue the Login process. If you don't get the
          link in Inbox, please check Spam.
        </Typography>
        <form onSubmit={() => firebase.resetPassword(email)}>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Enter your email address"
          />
          <button
            type="submit"
            onClick={(e) => {
              e.preventDefault();
              handleClose();
            }}
            style={{
              marginTop: "14px",
              padding: "20px",
              backgroundColor: "black",
              color: "white",
              border: "none",
              outline: "none",
              borderRadius: "5px",
            }}
          >
            Reset Password
          </button>
        </form>
      </Box>
    </Modal>
  );
};

export default BasicModal;
