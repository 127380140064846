import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import { Provider } from "react-redux";
import store, { rrfProps } from "./store";
import { ReactReduxFirebaseProvider } from "react-redux-firebase";

import Landing from "./components/layout/Landing";
import PrivateRoute from "./components/private-route/PrivateRoute";
import Dashboard from "./components/dashboard/Dashboard";
import Profile from "./components/profile/Profile";
import EditProfile from "./components/profile/EditProfile";
import LoginFirebase from "./components/auth/LoginFirebase";

import "./App.css";
import SignupFirebase from "./components/auth/SignupFirebase";
import VerifyNote from "./components/auth/VerifyNote";

const App = () => {
  return (
    <Provider store={store}>
      <ReactReduxFirebaseProvider {...rrfProps}>
        <Router>
          <div className="App">
            <Route exact path="/" component={Landing} />
            <Route exact path="/login" component={LoginFirebase} />
            <Route exact path="/register" component={SignupFirebase} />
            <Route exact path="/verify" component={VerifyNote} />
            <Switch>
              <PrivateRoute exact path="/dashboard" component={Dashboard} />
              <PrivateRoute exact path="/profile" component={Profile} />
              <PrivateRoute exact path="/edit" component={EditProfile} />
            </Switch>
          </div>
        </Router>
      </ReactReduxFirebaseProvider>
    </Provider>
  );
};

export default App;
