import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";
import Loading from "../layout/Loading";

const PrivateRoute = ({ component: Component, ...rest }) => {
  const firebaseReducer = useSelector((state) => state.firebase);
  const { isEmpty, isLoaded, emailVerified } = firebaseReducer.auth;

  if (!isLoaded) {
    return (
      <div
        style={{
          background: "#eceff1",
          height: "100vh",
          paddingLeft: "50%",
          paddingTop: "10%",
        }}
      >
        <Loading />
      </div>
    );
  }

  return (
    <Route
      {...rest}
      render={(props) =>
        !isEmpty && emailVerified ? (
          <Component {...props} />
        ) : (
          <Redirect to="/login" />
        )
      }
    />
  );
};

export default PrivateRoute;
